import React, { useEffect } from "react";
import "./JesusSuncatcherProduct.css";
import ImageGallery from 'react-image-gallery';
import galleryImg1 from "../../../images/products/jesus_suncatcher_01.webp";
import galleryImg2 from "../../../images/products/jesus_suncatcher_02.webp";
import galleryImg3 from "../../../images/products/jesus_suncatcher_03.webp";
import { useNavigate } from "react-router-dom";

const images = [
  {
    original: galleryImg1,
    thumbnail: galleryImg1
  },
  {
    original: galleryImg2,
    thumbnail: galleryImg2
  },
  {
    original: galleryImg3,
    thumbnail: galleryImg3
  }
];

export const JesusSuncatcherProduct = () => {
  const productName = "Jézus Krisztus ablakdísz";
  const navigate = useNavigate();

  const navigateToOrderDialog = () => {
    navigate('order');
  }

  useEffect(() => {
    document.title = 'ViaMedia - ' + productName;
  }, []);

  return (
    <div className="product">
      <div className="product_left">
        <div className="image-gallery-wrapper">
          <ImageGallery items={images} showFullscreenButton={false}/>      
        </div>
      </div>
      <div className="product_right">

        <h2>{productName}</h2>
        <p></p>
        <p>Ez az ablakdísz tökéletes választás lehet, hogy még szebbé és hangulatosabbá tegyük az otthonunkat. Ezenkívül emlékeztethet minket arra, hogy mindig van valaki, aki vigyáz ránk, és inspirálhat minket a mindennapi életben. Ez a gyönyörű dekoráció egyedi Jézus Krisztus motívummal rendelkezik, amely nemcsak szépséget, hanem vallási jelentőséget is kölcsönöz az otthonunknak. Így ez egy remek húsvéti dekorációs alternatíva lehet a nyuszi helyett, amely kiemeli az ünnep vallási jelentőségét.</p>
        <p>15x15cm, akrilból készült</p>

        <div className="orderButtonContainer">
          <button onClick={navigateToOrderDialog} type="button" class="pulsingButton" data-toggle="modal" data-target="#myModal">Megrendelés</button>
        </div>

      </div>
  </div>

  );
};
