import "./App.css";
import NavBar from "./components/NavBar";
import { BrowserRouter as Router,  Route, Routes } from "react-router-dom";
import { AdventTableDecorationProduct } from "./components/Pages/Products/AdventTableDecorationProduct";
import { About } from "./components/Pages/About";
import { Contact } from "./components/Pages/Contact";
import { AnnouncementBar } from "./components/AnnouncementBar";
import { Footer } from "./components/Footer";
import AdventTableDecorationOrder from "./components/Pages/Order/AdventTableDecorationOrder"
import SaleAdventTableDecorationOrder from "./components/Pages/SalesOrders/AdventTableDecorationOrder"
import NecklaceWithCrossOrder from "./components/Pages/Order/NecklaceWithCrossOrder"
import { SaleNecklaceWithCrossProduct } from "./components/Pages/SalesProducts/SaleNecklaceWithCrossProduct";
import SaleNecklaceWithCrossOrder from "./components/Pages/SalesOrders/SaleNecklaceWithCrossOrder"
import { SuccessfulOrder } from "./components/Pages/SuccessfulOrder";
import { NecklaceWithCrossProduct } from "./components/Pages/Products/NecklaceWithCrossProduct";
import { SaleEnded } from "./components/Pages/SaleEnded";
import { useState } from 'react';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import { ChakraBraceletProduct } from "./components/Pages/Products/ChakraBraceletProduct";
import ChakraBraceletOrder from "./components/Pages/Order/ChakraBraceletOrder";
import { SaleChakraBraceletProduct } from "./components/Pages/SalesProducts/SaleChakraBraceletProduct";
import SaleChakraBraceletOrder from "./components/Pages/SalesOrders/SaleChakraBraceletOrder";
import { AventurineBraceletProduct } from "./components/Pages/Products/AventurineBraceletProduct";
import AventurineBraceletOrder from "./components/Pages/Order/AventurineBraceletOrder";
import { SaleAventurineBraceletProduct } from "./components/Pages/SalesProducts/SaleAventurineBraceletProduct";
import SaleAventurineBraceletOrder from "./components/Pages/SalesOrders/SaleAventurineBraceletOrder";
import { BookmarkProduct } from "./components/Pages/Products/BookmarkProduct";
import BookmarkOrder from "./components/Pages/Order/BookmarkOrder";
import SaleBookmarkOrder from "./components/Pages/SalesOrders/SaleBookmarkOrder";
import { SaleBookmarkProduct } from "./components/Pages/SalesProducts/SaleBookmarkProduct";
import { RosaryProduct } from "./components/Pages/Products/RosaryProduct";
import RosaryOrder from "./components/Pages/Order/RosaryOrder";
import SaleRosaryOrder from "./components/Pages/SalesOrders/SaleRosaryOrder";
import { SaleRosaryProduct } from "./components/Pages/SalesProducts/SaleRosaryProduct";
import { NecklaceWithEyesProduct } from "./components/Pages/Products/NecklaceWithEyesProduct";
import NecklaceWithEyesOrder from "./components/Pages/Order/NecklaceWithEyesOrder";
import { SaleNecklaceWithEyesProduct } from "./components/Pages/SalesProducts/SaleNecklaceWithEyesProduct";
import SaleNecklaceWithEyesOrder from "./components/Pages/SalesOrders/SaleNecklaceWithEyesOrder";
import { JesusSuncatcherProduct } from "./components/Pages/Products/JesusSuncatcherProduct";
import JesusSuncatcherOrder from "./components/Pages/Order/JesusSuncatcherOrder";
import { SaleJesusSuncatcherProduct } from "./components/Pages/SalesProducts/SaleJesusSuncatcherProduct";
import SaleJesusSuncatcherOrder from "./components/Pages/SalesOrders/SaleJesusSuncatcherOrder";
import { SilverCrossBracletProduct } from "./components/Pages/Products/SilverCrossBracletProduct";
import SilverCrossBracletOrder from "./components/Pages/Order/SilverCrossBracletOrder";
import SaleSilverCrossBracletOrder from "./components/Pages/SalesOrders/SaleSilverCrossBracletOrder";
import { SaleSilverCrossBracletProduct } from "./components/Pages/SalesProducts/SaleSilverCrossBracletProduct";
import { CrossKeychainProduct } from "./components/Pages/Products/CrossKeychainProduct";
import CrossKeychainOrder from "./components/Pages/Order/CrossKeychainOrder";
import { SaleCrossKeychainProduct } from "./components/Pages/SalesProducts/SaleCrossKeychainProduct";
import SaleCrossKeychainOrder from "./components/Pages/SalesOrders/SaleCrossKeychainOrder";
import CrossfishNecklaceOrder from "./components/Pages/Order/CrossfishNecklaceOrder";
import { CrossfishNecklaceProduct } from "./components/Pages/Products/CrossfishNecklaceProduct";
import SaleCrossfishNecklaceOrder from "./components/Pages/SalesOrders/SaleCrossfishNecklaceOrder";
import { SaleCrossfishNecklaceProduct } from "./components/Pages/SalesProducts/SaleCrossfishNecklaceProduct";


  

function App() {

  const tagManagerArgs = {
    gtmId: 'G-B87K52GX7Q'
  }
  TagManager.initialize(tagManagerArgs)
  
  const TRACKING_ID = "UA-XXXXX-X";
  ReactGA.initialize(TRACKING_ID);

  const [showNavbar, setShowNavbar] = useState(true);

  return (
    <>
      <Router>
        <AnnouncementBar />      
        {showNavbar && <NavBar />}  

        <div className="pages">
          <Routes>
            <Route path="/" element={<NecklaceWithEyesProduct setShowNavbar={setShowNavbar}/>} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />

            <Route path="/adventTableDecorationProduct" element={<AdventTableDecorationProduct />} />
            <Route path="/5253eaf2-15f7-4d79-b0c8-822700f05edb" element={<SaleEnded />} />   
            <Route path="/fad69efe-bf8e-4bb3-a3ed-f94004464c0f" element={<SaleEnded />} />         
            <Route path="/adventTableDecorationOrder" element={<AdventTableDecorationOrder />} />

            <Route path="/necklaceWithCross" element={<NecklaceWithCrossProduct />} />
            <Route path="/necklaceWithCross/order" element={<NecklaceWithCrossOrder />} />
            <Route path="/08677255-bdac-4a32-b69b-237bfaca985a" element={<SaleEnded />} />
            <Route path="/06b42a84-8839-455c-9a83-9e16c8b1d30b" element={<SaleNecklaceWithCrossProduct setShowNavbar={setShowNavbar} />} />
            <Route path="/06b42a84-8839-455c-9a83-9e16c8b1d30b/order" element={<SaleNecklaceWithCrossOrder setShowNavbar={setShowNavbar} />} />

            <Route path="/chakraBracelet" element={<ChakraBraceletProduct />} />
            <Route path="/chakraBracelet/order" element={<ChakraBraceletOrder />} />
            <Route path="/108496a3-13ff-46db-9b99-66aa4dbe9890" element={<SaleChakraBraceletProduct setShowNavbar={setShowNavbar} />} />
            <Route path="/108496a3-13ff-46db-9b99-66aa4dbe9890/order" element={<SaleChakraBraceletOrder setShowNavbar={setShowNavbar} />} />

            <Route path="/aventurineBracelet" element={<AventurineBraceletProduct />} />            
            <Route path="/aventurineBracelet/order" element={<AventurineBraceletOrder />} />
            <Route path="/3d05c922-a9ce-4df9-8e30-cded0e875df6" element={<SaleAventurineBraceletProduct setShowNavbar={setShowNavbar} />} />
            <Route path="/3d05c922-a9ce-4df9-8e30-cded0e875df6/order" element={<SaleAventurineBraceletOrder setShowNavbar={setShowNavbar} />} />

            <Route path="/bookmark" element={<BookmarkProduct />} />            
            <Route path="/bookmark/order" element={<BookmarkOrder />} />
            <Route path="/1fdabc54-ee42-44e8-8497-e17dd1296048" element={<SaleBookmarkProduct setShowNavbar={setShowNavbar} />} />
            <Route path="/1fdabc54-ee42-44e8-8497-e17dd1296048/order" element={<SaleBookmarkOrder setShowNavbar={setShowNavbar} />} />

            <Route path="/rosary" element={<RosaryProduct />} />            
            <Route path="/rosary/order" element={<RosaryOrder />} />
            <Route path="/689648ef-69aa-4630-bf98-65ffb6dd491f" element={<SaleRosaryProduct setShowNavbar={setShowNavbar} />} />
            <Route path="/689648ef-69aa-4630-bf98-65ffb6dd491f/order" element={<SaleRosaryOrder setShowNavbar={setShowNavbar} />} />

            <Route path="/necklaceWithEyes" element={<NecklaceWithEyesProduct />} />            
            <Route path="/necklaceWithEyes/order" element={<NecklaceWithEyesOrder />} />
            <Route path="/19ff76d7-6883-45c4-ab83-77782fceba69" element={<SaleNecklaceWithEyesProduct setShowNavbar={setShowNavbar} />} />
            <Route path="/19ff76d7-6883-45c4-ab83-77782fceba69/order" element={<SaleNecklaceWithEyesOrder setShowNavbar={setShowNavbar} />} />

            <Route path="/jesusSuncatcher" element={<JesusSuncatcherProduct />} />            
            <Route path="/jesusSuncatcher/order" element={<JesusSuncatcherOrder />} />
            <Route path="/3cf0d3a1-3b60-4857-a816-0ca6b693a9cb" element={<SaleJesusSuncatcherProduct setShowNavbar={setShowNavbar} />} />
            <Route path="/3cf0d3a1-3b60-4857-a816-0ca6b693a9cb/order" element={<SaleJesusSuncatcherOrder setShowNavbar={setShowNavbar} />} />

            <Route path="/silverCrossBraclet" element={<SilverCrossBracletProduct />} />            
            <Route path="/silverCrossBraclet/order" element={<SilverCrossBracletOrder />} />
            <Route path="/d4d8ec2e-bc33-4331-a06f-2ce38648a336" element={<SaleSilverCrossBracletProduct setShowNavbar={setShowNavbar} />} />
            <Route path="/d4d8ec2e-bc33-4331-a06f-2ce38648a336/order" element={<SaleSilverCrossBracletOrder setShowNavbar={setShowNavbar} />} />
            
            <Route path="/crossKeychain" element={<CrossKeychainProduct />} />
            <Route path="/crossKeychain/order" element={<CrossKeychainOrder />} />
            <Route path="/8c25027e-8e6b-4af6-a114-6d2ace71c79a" element={<SaleCrossKeychainProduct setShowNavbar={setShowNavbar} />} />
            <Route path="/8c25027e-8e6b-4af6-a114-6d2ace71c79a/order" element={<SaleCrossKeychainOrder setShowNavbar={setShowNavbar} />} />

            <Route path="/crossfishNecklace" element={<CrossfishNecklaceProduct />} />
            <Route path="/crossfishNecklace/order" element={<CrossfishNecklaceOrder />} />
            <Route path="/ae41f8f2-3e84-4388-a326-634aa5cdd104" element={<SaleCrossfishNecklaceProduct setShowNavbar={setShowNavbar} />} />
            <Route path="/ae41f8f2-3e84-4388-a326-634aa5cdd104/order" element={<SaleCrossfishNecklaceOrder setShowNavbar={setShowNavbar} />} />

            
            <Route path="/successfulOrder" element={<SuccessfulOrder />} />
          </Routes>
        </div>
        <Footer />
      </Router>
  </>
  );
}

export default App;
