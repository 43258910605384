import React, { useEffect } from "react";
import "./CrossKeychainProduct.css";
import ImageGallery from 'react-image-gallery';
import galleryImg1 from "../../../images/products/cross_keychain_01.webp";
import galleryImg2 from "../../../images/products/cross_keychain_02.webp";
import galleryImg3 from "../../../images/products/cross_keychain_03.webp";
import galleryImg4 from "../../../images/products/cross_keychain_04.webp";
import galleryImg5 from "../../../images/products/cross_keychain_05.webp";
import { useNavigate } from "react-router-dom";

const images = [
  {
    original: galleryImg1,
    thumbnail: galleryImg1
  },
  {
    original: galleryImg2,
    thumbnail: galleryImg2
  },
  {
    original: galleryImg3,
    thumbnail: galleryImg3
  },
  {
    original: galleryImg4,
    thumbnail: galleryImg4
  },
  {
    original: galleryImg5,
    thumbnail: galleryImg5
  }
];

export const CrossKeychainProduct = () => {
  const productName = "Jézus kereszt medál kulcstartó";
  const navigate = useNavigate();

  const navigateToOrderDialog = () => {
    navigate('order');
  }

  useEffect(() => {
    document.title = 'ViaMedia - ' + productName;
  }, []);

  return (
    <div className="product">
      <div className="product_left">
        <div className="image-gallery-wrapper">
          <ImageGallery items={images} showFullscreenButton={false}/>      
        </div>
      </div>
      <div className="product_right">

        <h2>{productName}</h2>
        <p></p>
        <p>Ez a kulcstartó, mely a keresztet, a megváltás és a hit szimbólumát ábrázolja, mindennapjainkban is emlékeztet bennünket az Istennel való kapcsolatunkra és hitünk erejére.</p>
        <p>Tökéletes választás mindazok számára, akik szeretnék megélni és kifejezni hitüket a mindennapokban, és egyben személyes, szívből jövő ajándék is lehet szeretteink számára.</p>
        <p>Anyaga: Rozsdamentes acél</p>
        <p>Karika átmérője: 25mm</p>
        <p>Medál mérete: 41mm x 25mm</p>

        <div className="orderButtonContainer">
          <button onClick={navigateToOrderDialog} type="button" class="pulsingButton" data-toggle="modal" data-target="#myModal">Megrendelés</button>
        </div>

      </div>
  </div>

  );
};
