import "./AventurineBraceletOrder.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom'; 
import productImg from "../../../images/products/aventurine_braclet_4.webp";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import * as formik from 'formik';
import * as yup from 'yup';
import numeral from "numeral";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function AventurineBraceletOrder() {
  const productName = "Zöld Aventurin Karkötő";
  const price = 7990;
  const shippingFee = 290;

  const totalPrice = price+shippingFee;
  const priceText = numeral(price).format('0,0');
  const totalPriceText = numeral(totalPrice).format('0,0');
  const queryParams = new URLSearchParams(useLocation().search);  
  const userId = queryParams.get('userId');

  const firstname = (!queryParams.get('firstname') || queryParams.get('firstname') === "{firstname}") ? "" : queryParams.get('firstname');
  const lastname = (!queryParams.get('lastname') || queryParams.get('lastname') === "{lastname}") ? "" : queryParams.get('lastname');

  const { Formik } = formik;

  const schema = yup.object().shape({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    postcode: yup.string().required(),
    city: yup.string().required(),    
    address: yup.string().required(),
    phone: yup.string().required()
  });

  const navigate = useNavigate();
  const navigateToSuccessfulOrder = () => {
    navigate('/successfulOrder');
  }

  useEffect(() => {
    document.title = 'ViaMedia - ' + productName + ' - Megrendelőlap';
  }, []);

    return (
      <div className="order-dialog">
        <div className="order-container">
          <div className="order-header">
            {productName} megrendelő
          </div>
          <div className="order-offers">
            <div className="order-offers-row selected">
              <div className="order-offers-row-image">
                <img src={productImg} alt={productName} width="54px" height="54px"/>
              </div>
              <div className="order-offers-row-info">
                <div className="order-offers-row-info-title">
                  1db {productName}
                </div>
                <div className="order-offers-row-info-plaque-container">
                </div>
              </div>
              <div className="order-offers-row-prices">
                <div className="order-offers-row-prices-new">{priceText} Ft</div>
              </div>
            </div>
          </div>
          <div className="order-free-shipping-message">
            Ingyenes házhozszállítás futárral!
          </div>
          <div className="order-shipping-header">
            Hová küldjük a csomagod?
          </div>
          <div className="order-shipping-form-container">
          <Formik
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              const payload = JSON.stringify(values, null, 2);
              axios.post('/api/saveOrder', payload, {
                headers: {'Content-Type': 'application/json'}
              })
              .then(function (response) {
                console.log(response);
                navigateToSuccessfulOrder()
              })
              .catch(function (error) {
                console.log(error);
              });
            }}
            initialValues={{
              date: new Date().toISOString(),
              productName: productName,
              userId: userId,
              firstname: firstname,
              lastname: lastname,
              postcode: "",
              city: "",
              address: "",
              phone: "",
              totalPrice: totalPriceText
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form className="order-shipping-form" noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="validationLastname">
                  <InputGroup className="mb-3" hasValidation>
                    <InputGroup.Text id="basic-addon1">Vezetéknév</InputGroup.Text>
                    <Form.Control type="text" name="lastname" value={values.lastname} onChange={handleChange} isValid={touched.lastname && !errors.lastname} isInvalid={!!errors.lastname}/>
                    <Form.Control.Feedback type="invalid">Kérjük töltsd ki ezt a mezőt is!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formFirstname">
                  <InputGroup className="mb-3" hasValidation>
                    <InputGroup.Text id="basic-addon1">Keresztnév</InputGroup.Text>
                    <Form.Control type="text" name="firstname" value={values.firstname} onChange={handleChange} isValid={touched.firstname && !errors.firstname} isInvalid={!!errors.firstname}/>
                    <Form.Control.Feedback type="invalid">Kérjük töltsd ki ezt a mezőt is!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPostcode">
                  <InputGroup className="mb-3" hasValidation>
                    <InputGroup.Text id="basic-addon1">Irányítószám</InputGroup.Text>
                    <Form.Control type="text" name="postcode" value={values.postcode} onChange={handleChange} isValid={touched.postcode && !errors.postcode} isInvalid={!!errors.postcode}/>
                    <Form.Control.Feedback type="invalid">Kérjük töltsd ki ezt a mezőt is!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formCity">
                  <InputGroup className="mb-3" hasValidation>
                    <InputGroup.Text id="basic-addon1">Település</InputGroup.Text>
                    <Form.Control type="text" name="city" value={values.city} onChange={handleChange} isValid={touched.city && !errors.city} isInvalid={!!errors.city}/>
                    <Form.Control.Feedback type="invalid">Kérjük töltsd ki ezt a mezőt is!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formAddress">
                  <InputGroup className="mb-3" hasValidation>
                    <InputGroup.Text id="basic-addon1">Utca és házszám</InputGroup.Text>
                    <Form.Control type="text" name="address" aria-describedby="inputGroupPrepend" value={values.address} onChange={handleChange} isValid={touched.city && !errors.address} isInvalid={!!errors.address}/>
                    <Form.Control.Feedback type="invalid">Kérjük töltsd ki ezt a mezőt is!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPhone">
                  <InputGroup className="mb-3" hasValidation>
                    <InputGroup.Text id="basic-addon1">Telefonszám</InputGroup.Text>
                    <Form.Control type="text" name="phone" placeholder="A futárnak kell" value={values.phone} onChange={handleChange} isValid={touched.phone && !errors.phone} isInvalid={!!errors.phone}/>
                    <Form.Control.Feedback type="invalid">Kérjük töltsd ki ezt a mezőt is!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Button className="pulsingButton" variant="success" type="submit" size="lg">Megrendelem</Button>
              </Form>
          )}
          </Formik>
          </div>
          
          <div className="order-summary">
            <div className="order-checkout-line">
              <span className="order-checkout-line-title">Házhozszállítás (1-2 nap)</span>
              <span className="order-checkout-line-amount">0 Ft</span>
            </div>
            <div className="order-checkout-line">
              <span className="order-checkout-line-title">Utánvét Díj</span>
              <span className="order-checkout-line-amount">{shippingFee} Ft</span>
            </div>

            <div className="order-checkout-total">
              <span className="order-checkout-line-title">Összesen</span>
              <span className="order-checkout-line-amount">{totalPriceText} Ft</span>
            </div>
          </div>
          
          <div className="order-benefits">
            <div>✅ Gyors szállítás futárral</div>
            <div>✅ Biztonságos utánvétes fizetés</div>
          </div>
        </div>
      </div>
    );
  }
  
  export default AventurineBraceletOrder;