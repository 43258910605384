import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom'; 
import "./SaleNecklaceWithEyesProduct.css";
import ImageGallery from 'react-image-gallery';
import galleryImg1 from "../../../images/products/eyes_necklace_01.webp";
import galleryImg2 from "../../../images/products/eyes_necklace_02.webp";
import galleryImg3 from "../../../images/products/eyes_necklace_03.webp";
import galleryImg4 from "../../../images/products/eyes_necklace_04.webp";
import galleryImg5 from "../../../images/products/eyes_necklace_05.webp";
import galleryImg6 from "../../../images/products/eyes_necklace_06.webp";
import { useNavigate } from "react-router-dom";

const images = [
    {
      original: galleryImg1,
      thumbnail: galleryImg1
    },
    {
      original: galleryImg2,
      thumbnail: galleryImg2
    },
    {
      original: galleryImg3,
      thumbnail: galleryImg3
    },
    {
      original: galleryImg4,
      thumbnail: galleryImg4
    },
    {
      original: galleryImg5,
      thumbnail: galleryImg5
    },
    {
      original: galleryImg6,
      thumbnail: galleryImg6
    }
  ];

export const SaleNecklaceWithEyesProduct = () => {
  const productName = "Nyaklánc szem medállal";

  const queryParams = new URLSearchParams(useLocation().search);  
  const userId = queryParams.get('userId');
  const firstname = (!queryParams.get('firstname') || queryParams.get('firstname') === "{{firstname}}") ? "" : queryParams.get('firstname');
  const lastname = (!queryParams.get('lastname') || queryParams.get('lastname') === "{{lastname}}") ? "" : queryParams.get('lastname');

  const navigate = useNavigate();

  const navigateToOrderDialog = () => {
    navigate('order?firstname=' + firstname + '&lastname=' + lastname + '&userId=' + userId);
  }

  useEffect(() => {
    document.title = 'ViaMedia - ' + productName + ' Akció';
  }, []);

  return (
    <div className="product">
      <div className="product_left">
        <div className="image-gallery-wrapper">
          <ImageGallery items={images} showFullscreenButton={false}/>      
        </div>
      </div>
      <div className="product_right">

      <h2>{productName}</h2>
        <p></p>
        <p>Ez a gyönyörű nyaklánc és medál mély spirituális jelentéssel bír. A szem a megfigyelést és a megvilágosodást szimbolizálja.</p>
        <p>Ez a medál kifejezi az Isten iránti hűségünket, a szem figyelő tekintete emlékeztet minket arra, hogy mindig Isten szeme előtt vagyunk.</p>
        <p>A medál és a lánc minőségi anyagokból készült, és gondosan kidolgozott részletekkel rendelkezik, ezáltal hosszú távon is megőrzi szépségét.</p>
        <p></p>
        <p>41+5cm állítható, rose gold aranyozott</p>
        <p></p>

        <div className="orderButtonContainer">
          <button onClick={navigateToOrderDialog} type="button" class="pulsingButton" data-toggle="modal" data-target="#myModal">Akciós árak és megrendelés</button>
        </div>

      </div>
  </div>

  );
};
