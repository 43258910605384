import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom'; 
import "./SaleCrossKeychainProduct.css";
import ImageGallery from 'react-image-gallery';
import galleryImg1 from "../../../images/products/cross_keychain_01.webp";
import galleryImg2 from "../../../images/products/cross_keychain_02.webp";
import galleryImg3 from "../../../images/products/cross_keychain_03.webp";
import galleryImg4 from "../../../images/products/cross_keychain_04.webp";
import galleryImg5 from "../../../images/products/cross_keychain_05.webp";
import { useNavigate } from "react-router-dom";

const images = [
    {
      original: galleryImg1,
      thumbnail: galleryImg1
    },
    {
      original: galleryImg2,
      thumbnail: galleryImg2
    },
    {
      original: galleryImg3,
      thumbnail: galleryImg3
    },
    {
      original: galleryImg4,
      thumbnail: galleryImg4
    },
    {
      original: galleryImg5,
      thumbnail: galleryImg5
    }
  ];
  

export const SaleCrossKeychainProduct = () => {
    const productName = "Jézus kereszt medál kulcstartó";

  const queryParams = new URLSearchParams(useLocation().search);  
  const userId = queryParams.get('userId');
  const firstname = (!queryParams.get('firstname') || queryParams.get('firstname') === "{{firstname}}") ? "" : queryParams.get('firstname');
  const lastname = (!queryParams.get('lastname') || queryParams.get('lastname') === "{{lastname}}") ? "" : queryParams.get('lastname');

  const navigate = useNavigate();

  const navigateToOrderDialog = () => {
    navigate('order?firstname=' + firstname + '&lastname=' + lastname + '&userId=' + userId);
  }

  useEffect(() => {
    document.title = 'ViaMedia - ' + productName + ' Akció';
  }, []);

  return (
    <div className="product">
      <div className="product_left">
        <div className="image-gallery-wrapper">
          <ImageGallery items={images} showFullscreenButton={false}/>      
        </div>
      </div>
      <div className="product_right">

        <h2>{productName}</h2>
        <p></p>
        <p>Ez a kulcstartó, mely a keresztet, a megváltás és a hit szimbólumát ábrázolja, mindennapjainkban is emlékeztet bennünket az Istennel való kapcsolatunkra és hitünk erejére.</p>
        <p>Tökéletes választás mindazok számára, akik szeretnék megélni és kifejezni hitüket a mindennapokban, és egyben személyes, szívből jövő ajándék is lehet szeretteink számára.</p>
        <p>Anyaga: Rozsdamentes acél</p>
        <p>Karika átmérője: 25mm</p>
        <p>Medál mérete: 41mm x 25mm</p>

        <div className="orderButtonContainer">
          <button onClick={navigateToOrderDialog} type="button" class="pulsingButton" data-toggle="modal" data-target="#myModal">Akciós árak és megrendelés</button>
        </div>

      </div>
  </div>

  );
};
