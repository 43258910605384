import React from "react";
import "./Footer.css";

export const Footer = () => {
  return (
    <div className="footer">
        <div className="footer__content-bottom">
            <div className="footer__content-bottom-wrapper page-width">

            <div className="footer__column footer__column--info">
                <div className="footer__copyright caption">
                    <small className="copyright__content">© 2024, <a href="/" title="">ViaMedia</a></small>
                </div>
            </div>
            </div>
        </div>
    </div>
  );
};