import React, { useEffect } from "react";
import "./SilverCrossBracletProduct.css";
import ImageGallery from 'react-image-gallery';
import galleryImg1 from "../../../images/products/silver_cross_braclet_01.jpg";
import galleryImg2 from "../../../images/products/silver_cross_braclet_02.jpg";
import galleryImg3 from "../../../images/products/silver_cross_braclet_03.jpg";
import galleryImg4 from "../../../images/products/silver_cross_braclet_04.jpg";
import galleryImg5 from "../../../images/products/silver_cross_braclet_05.jpg";
import { useNavigate } from "react-router-dom";

const images = [
  {
    original: galleryImg1,
    thumbnail: galleryImg1
  },
  {
    original: galleryImg2,
    thumbnail: galleryImg2
  },
  {
    original: galleryImg3,
    thumbnail: galleryImg3
  },
  {
    original: galleryImg4,
    thumbnail: galleryImg4
  },
  {
    original: galleryImg5,
    thumbnail: galleryImg5
  }
];

export const SilverCrossBracletProduct = () => {
  const productName = "Ezüst karkötő kereszt medállal";
  const navigate = useNavigate();

  const navigateToOrderDialog = () => {
    navigate('order');
  }

  useEffect(() => {
    document.title = 'ViaMedia - ' + productName;
  }, []);

  return (
    <div className="product">
      <div className="product_left">
        <div className="image-gallery-wrapper">
          <ImageGallery items={images} showFullscreenButton={false}/>      
        </div>
      </div>
      <div className="product_right">

        <h2>{productName}</h2>
        <p></p>
        <p>Ez a gyönyörű ezüst karkötő a keresztény vallás egyik legfontosabb szimbólumát, Jézus Krisztus keresztrefeszítését és feltámadását jelképezi. A viselése segíthet a hit megerősítésében. Kiváló választás lehet ajándéknak is.</p>
        <p>Anyaga: Ezüst (925 Sterling Silver)</p>
        <p>Medál díszítése: Cirkon</p>
        <p>Mérete: 17+3cm</p>

        <div className="orderButtonContainer">
          <button onClick={navigateToOrderDialog} type="button" class="pulsingButton" data-toggle="modal" data-target="#myModal">Megrendelés</button>
        </div>

      </div>
  </div>

  );
};
