import React from "react";
import "./AnnouncementBar.css";

export const AnnouncementBar = () => {
  return (
    <div id="shopify-section-announcement-bar" className="shopify-section">
        <div className="announcement-bar color-accent-2 gradient" role="region" aria-label="Közlemény">
            <div className="page-width">
                <p className="announcement-bar__message center h5">
                  Most Ingyenes Házhozszállítás!
                </p>
            </div>
        </div>
    </div>
  );
};