import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom'; 
import "./SaleChakraBraceletProduct.css";
import ImageGallery from 'react-image-gallery';
import galleryImg1 from "../../../images/products/chacra_braclet_1.webp";
import galleryImg2 from "../../../images/products/chacra_braclet_2.webp";
import galleryImg3 from "../../../images/products/chacra_braclet_3.jpg";
import { useNavigate } from "react-router-dom";

const images = [
  {
    original: galleryImg1,
    thumbnail: galleryImg1
  },
  {
    original: galleryImg2,
    thumbnail: galleryImg2
  },
  {
    original: galleryImg3,
    thumbnail: galleryImg3
  }
];

export const SaleChakraBraceletProduct = () => {
  const productName = "7 csakraköves karkötő";

  const queryParams = new URLSearchParams(useLocation().search);  
  const userId = queryParams.get('userId');
  const firstname = (!queryParams.get('firstname') || queryParams.get('firstname') === "{{firstname}}") ? "" : queryParams.get('firstname');
  const lastname = (!queryParams.get('lastname') || queryParams.get('lastname') === "{{lastname}}") ? "" : queryParams.get('lastname');

  const navigate = useNavigate();

  const navigateToOrderDialog = () => {
    navigate('order?firstname=' + firstname + '&lastname=' + lastname + '&userId=' + userId);
  }

  useEffect(() => {
    document.title = 'ViaMedia - ' + productName + ' Akció';
  }, []);

  return (
    <div className="product">
      <div className="product_left">
        <div className="image-gallery-wrapper">
          <ImageGallery items={images} showFullscreenButton={false}/>      
        </div>
      </div>
      <div className="product_right">


        <h2>{productName}</h2>
        <p></p>
        <p>Valódi lávaköves karkötő 7 csakrakővel:</p>
        <p></p>
        <p><b>Gyökér csakra: </b>A <b>Vörös Achát</b> stabilizálja az aurát, elnyeli és átalakítja a negatív energiákat.</p>
        <p><b>Szakrális csakra: </b> A <b>Borostyánkő</b> a csakrák rendkívül hatékony tisztítója és gyógyítója.</p>
        <p><b>Napfonat csakra: </b>A <b>Tigrisszem</b> jótékonyan hat a szemre, de hasznos a törött csontok helyrehozatalában is.</p>
        <p><b>Szív csakra: </b> A <b>Jáde</b> jótékony a vesékre és a mellékvesére, eltávolítja a méreganyagokat.</p>
        <p><b>Torok csakra: </b>A <b>Türkiz</b> erősíti a test meridiánjait és a finom energiamezőket.</p>
        <p><b>Szem csakra: </b> A <b>Lazulit (kék pát)</b> enyhíti a fájdalmat, különösen a migrénes fejfájással együtt járót.</p>
        <p><b>Korona csakra: </b> Az <b>Ametiszt</b> serkenti a hormonképződést; összehangolja a belső elválasztású rendszert és az anyagcserét.</p>

        <div className="orderButtonContainer">
          <button onClick={navigateToOrderDialog} type="button" class="pulsingButton" data-toggle="modal" data-target="#myModal">Akciós árak és megrendelés</button>
        </div>

      </div>
  </div>

  );
};
