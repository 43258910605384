import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom'; 
import "./SaleBookmarkProduct.css";
import ImageGallery from 'react-image-gallery';
import galleryImg1 from "../../../images/products/bookmark_1.jpg";
import galleryImg2 from "../../../images/products/bookmark_2.jpg";
import { useNavigate } from "react-router-dom";

const images = [
  {
    original: galleryImg1,
    thumbnail: galleryImg1
  },
  {
    original: galleryImg2,
    thumbnail: galleryImg2
  }
];

export const SaleBookmarkProduct = () => {
  const productName = "Bibliai könyvjelző";

  const queryParams = new URLSearchParams(useLocation().search);  
  const userId = queryParams.get('userId');
  const firstname = (!queryParams.get('firstname') || queryParams.get('firstname') === "{{firstname}}") ? "" : queryParams.get('firstname');
  const lastname = (!queryParams.get('lastname') || queryParams.get('lastname') === "{{lastname}}") ? "" : queryParams.get('lastname');

  const navigate = useNavigate();

  const navigateToOrderDialog = () => {
    navigate('order?firstname=' + firstname + '&lastname=' + lastname + '&userId=' + userId);
  }

  useEffect(() => {
    document.title = 'ViaMedia - ' + productName + ' Akció';
  }, []);

  return (
    <div className="product">
      <div className="product_left">
        <div className="image-gallery-wrapper">
          <ImageGallery items={images} showFullscreenButton={false}/>      
        </div>
      </div>
      <div className="product_right">

      <h2>{productName}</h2>
        <p></p>
        <p>A könyvjelző a bibliaolvasás elengedhetetlen kiegészítője, mely lehetővé teszi az olvasó számára, hogy könnyen visszataláljon az utoljára olvasott igeszakaszkoz.</p>
        <p>Ez a fém könyvjelző tartós és elegáns megjelenésű. Vékony profilja lehetővé teszi, hogy könnyen behelyezhető legyen a Biblia vagy más könyv lapjai közé, és az olvasás közben sem zavarja az olvasót. A praktikum és az esztétikum tökéletes kombinációját képviseli.</p>
        <p></p>

        <div className="orderButtonContainer">
          <button onClick={navigateToOrderDialog} type="button" class="pulsingButton" data-toggle="modal" data-target="#myModal">Akciós árak és megrendelés</button>
        </div>

      </div>
  </div>

  );
};
