import React, { useEffect } from "react";
import { useLocation } from 'react-router-dom'; 
import "./SaleCrossfishNecklaceProduct.css";
import ImageGallery from 'react-image-gallery';
import galleryImg1 from "../../../images/products/crossfish_necklace_01.webp";
import galleryImg2 from "../../../images/products/crossfish_necklace_02.webp";
import galleryImg3 from "../../../images/products/crossfish_necklace_03.webp";
import galleryImg4 from "../../../images/products/crossfish_necklace_04.webp";
import { useNavigate } from "react-router-dom";

const images = [
    {
      original: galleryImg1,
      thumbnail: galleryImg1
    },
    {
      original: galleryImg2,
      thumbnail: galleryImg2
    },
    {
      original: galleryImg3,
      thumbnail: galleryImg3
    },
    {
      original: galleryImg4,
      thumbnail: galleryImg4
    }
  ];
  

export const SaleCrossfishNecklaceProduct = () => {
    const productName = "Hal+Kereszt nyaklánc";

  const queryParams = new URLSearchParams(useLocation().search);  
  const userId = queryParams.get('userId');
  const firstname = (!queryParams.get('firstname') || queryParams.get('firstname') === "{{firstname}}") ? "" : queryParams.get('firstname');
  const lastname = (!queryParams.get('lastname') || queryParams.get('lastname') === "{{lastname}}") ? "" : queryParams.get('lastname');

  const navigate = useNavigate();

  const navigateToOrderDialog = () => {
    navigate('order?firstname=' + firstname + '&lastname=' + lastname + '&userId=' + userId);
  }

  useEffect(() => {
    document.title = 'ViaMedia - ' + productName + ' Akció';
  }, []);

  return (
    <div className="product">
      <div className="product_left">
        <div className="image-gallery-wrapper">
          <ImageGallery items={images} showFullscreenButton={false}/>      
        </div>
      </div>
      <div className="product_right">

        <h2>{productName}</h2>
        <p></p>
        <p>Ez a gyönyörű nyaklánc két erőteljes szimbólumot egyesít: a halat és a keresztet.</p>
        <p>A hal szimbólum a kereszténység egyik ősi jelképe, amely a hitet és a megváltást jelképezi. A kereszt pedig az áldozat és a feltámadás szimbóluma, amely erőt és reményt ad a mindennapokban.</p>
        <p>Anyaga: Rozsdamentes acél</p>
        <p>Lánc hossza: 45 cm (állítható +5 cm)</p>
        <p>Medál mérete: 18mm x 46mm</p>

        <div className="orderButtonContainer">
          <button onClick={navigateToOrderDialog} type="button" class="pulsingButton" data-toggle="modal" data-target="#myModal">Akciós árak és megrendelés</button>
        </div>

      </div>
  </div>

  );
};
