import "./SaleCrossfishNecklaceOrder.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useLocation } from 'react-router-dom'; 
import productImg1 from "../../../images/products/crossfish_necklace_00_1.webp";
import productImg2 from "../../../images/products/crossfish_necklace_00_2.webp";
import productImg3 from "../../../images/products/crossfish_necklace_00_3.webp";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import * as formik from 'formik';
import * as yup from 'yup';
import numeral from "numeral";
import axios from "axios";
import { useState } from "react";
import { useLayoutEffect } from 'react';
import { useEffect } from "react";

function SaleCrossfishNecklaceOrder({ setShowNavbar }) {
  const productName = "Hal+Kereszt nyaklánc";
  const period = "24W36"
  const offers = [
    {quantity:1,oldPrice:null,newPrice:7990,saleText:null,productImg: productImg1,plaqueClassName:""},
    {quantity:2,oldPrice:15980,newPrice:7990,saleText:"50% Kedvezmény",productImg: productImg2,plaqueClassName:"second-offer"},
    {quantity:3,oldPrice:23970,newPrice:10229,saleText:"60% Kedvezmény",productImg: productImg3,plaqueClassName:"third-offer"}
  ];
  const defaultSelectedOffer = 1;
  const shippingFee = 0;
  const cashOnDeliveryFee = 290;

  var totalPrice = offers[defaultSelectedOffer].newPrice+shippingFee+cashOnDeliveryFee;
  const [totalPriceText, setTotalPriceText] = useState(numeral(totalPrice).format('0,0'));
  const queryParams = new URLSearchParams(useLocation().search);  
  const userId = queryParams.get('userId');

  const firstname = (!queryParams.get('firstname') || queryParams.get('firstname') === "{{firstname}}") ? "" : queryParams.get('firstname');
  const lastname = (!queryParams.get('lastname') || queryParams.get('lastname') === "{{lastname}}") ? "" : queryParams.get('lastname');

  const [selectedOffer, setSelectedOffer] = useState(offers[defaultSelectedOffer]);

  const { Formik } = formik;

  const schema = yup.object().shape({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    postcode: yup.string().required(),
    city: yup.string().required(),    
    address: yup.string().required(),
    phone: yup.string().required()
  });

  const navigateToSuccessfulOrder = () => {
    window.location.href = 'https://www.messenger.com/t/135010389691417/?ref=CrossfishNecklaceSuccessOrder_'+period;
  }

  const offerSelected = (offerIndex) => {
    totalPrice =offers[offerIndex].newPrice+shippingFee+cashOnDeliveryFee;
    setSelectedOffer(offers[offerIndex]);
    setTotalPriceText(numeral(totalPrice).format('0,0'));
  }

  useLayoutEffect(() => {
    setShowNavbar(false);
  }, [])

  useEffect(() => {
    document.title = 'ViaMedia - ' + productName + ' Akció - Megrendelőlap';
  }, []);

    return (
      <div className="order-dialog">
        <div className="order-container">
          <div className="order-header">
            {productName} megrendelő
          </div>
          <div className="order-offers">
            {
                offers.map((offer, index) => (
                    <div className={offer.quantity === selectedOffer.quantity ? 'order-offers-row selected' : 'order-offers-row'} onClick={() => offerSelected(index)}>
                    <div className="order-offers-row-image">
                        <img src={offer.productImg} alt={productName} width="54px" height="54px"/>
                    </div>
                    <div className="order-offers-row-info">
                        <div className="order-offers-row-info-title">
                        {offer.quantity}db {productName}
                        </div>
                        <div className="order-offers-row-info-plaque-container">
                        {offer.saleText ?
                            <div className={"order-offers-row-info-plaque " + offer.plaqueClassName}>
                                {offer.saleText}
                            </div>
                        : ''}
                        </div>
                    </div>
                    <div className="order-offers-row-prices">
                        { offer.oldPrice ? <div className="order-offers-row-prices-old">{numeral(offer.oldPrice).format('0,0')} Ft </div>: '' }
                        <div className="order-offers-row-prices-new">{numeral(offer.newPrice).format('0,0')} Ft</div>
                    </div>
                    </div>
                ))
            }
          </div>
          <div className="order-free-shipping-message">
            Ingyenes házhozszállítás futárral!
          </div>
          <div className="order-shipping-header">
            Hová küldjük a csomagod?
          </div>
          <div className="order-shipping-form-container">
          <Formik
            validationSchema={schema}
            onSubmit={(values, { setSubmitting }) => {
              values.quantity=selectedOffer.quantity;
              values.totalPrice=totalPriceText;

              const payload = JSON.stringify(values, null, 2);
              axios.post('/api/saveOrder', payload, {
                headers: {'Content-Type': 'application/json'}
              })
              .then(function (response) {                
                console.log(response);
                navigateToSuccessfulOrder()
              })
              .catch(function (error) {
                console.log(error);
              });
            }}
            initialValues={{
              date: new Date().toISOString(),
              productName: productName,
              userId: userId,
              firstname: firstname,
              lastname: lastname,
              postcode: "",
              city: "",
              address: "",
              phone: ""
            }}
          >
            {({ handleSubmit, handleChange, values, touched, errors }) => (
              <Form className="order-shipping-form" noValidate onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="validationLastname">
                  <InputGroup className="mb-3" hasValidation>
                    <InputGroup.Text id="basic-addon1">Vezetéknév</InputGroup.Text>
                    <Form.Control type="text" name="lastname" value={values.lastname} onChange={handleChange} isValid={touched.lastname && !errors.lastname} isInvalid={!!errors.lastname}/>
                    <Form.Control.Feedback type="invalid">Kérjük töltsd ki ezt a mezőt is!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formFirstname">
                  <InputGroup className="mb-3" hasValidation>
                    <InputGroup.Text id="basic-addon1">Keresztnév</InputGroup.Text>
                    <Form.Control type="text" name="firstname" value={values.firstname} onChange={handleChange} isValid={touched.firstname && !errors.firstname} isInvalid={!!errors.firstname}/>
                    <Form.Control.Feedback type="invalid">Kérjük töltsd ki ezt a mezőt is!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPostcode">
                  <InputGroup className="mb-3" hasValidation>
                    <InputGroup.Text id="basic-addon1">Irányítószám</InputGroup.Text>
                    <Form.Control type="text" name="postcode" value={values.postcode} onChange={handleChange} isValid={touched.postcode && !errors.postcode} isInvalid={!!errors.postcode}/>
                    <Form.Control.Feedback type="invalid">Kérjük töltsd ki ezt a mezőt is!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formCity">
                  <InputGroup className="mb-3" hasValidation>
                    <InputGroup.Text id="basic-addon1">Település</InputGroup.Text>
                    <Form.Control type="text" name="city" value={values.city} onChange={handleChange} isValid={touched.city && !errors.city} isInvalid={!!errors.city}/>
                    <Form.Control.Feedback type="invalid">Kérjük töltsd ki ezt a mezőt is!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formAddress">
                  <InputGroup className="mb-3" hasValidation>
                    <InputGroup.Text id="basic-addon1">Utca és házszám</InputGroup.Text>
                    <Form.Control type="text" name="address" aria-describedby="inputGroupPrepend" value={values.address} onChange={handleChange} isValid={touched.city && !errors.address} isInvalid={!!errors.address}/>
                    <Form.Control.Feedback type="invalid">Kérjük töltsd ki ezt a mezőt is!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formPhone">
                  <InputGroup className="mb-3" hasValidation>
                    <InputGroup.Text id="basic-addon1">Telefonszám</InputGroup.Text>
                    <Form.Control type="text" name="phone" placeholder="A futárnak kell" value={values.phone} onChange={handleChange} isValid={touched.phone && !errors.phone} isInvalid={!!errors.phone}/>
                    <Form.Control.Feedback type="invalid">Kérjük töltsd ki ezt a mezőt is!</Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>

                <Button className="pulsingButton" variant="success" type="submit" size="lg">Megrendelem</Button>
              </Form>
          )}
          </Formik>
          </div>
          
          <div className="order-summary">
            <div className="order-checkout-line">
              <span className="order-checkout-line-title">{selectedOffer.quantity}db {productName}</span>
              <span className="order-checkout-line-amount">{numeral(selectedOffer.newPrice).format('0,0')} Ft</span>
            </div>
            <div className="order-checkout-line">
              <span className="order-checkout-line-title">Házhozszállítás (2-3 nap)</span>
              <span className="order-checkout-line-amount">{shippingFee} Ft</span>
            </div>
            <div className="order-checkout-line">
              <span className="order-checkout-line-title">Utánvét Díj</span>
              <span className="order-checkout-line-amount">{cashOnDeliveryFee} Ft</span>
            </div>

            <div className="order-checkout-total">
              <span className="order-checkout-line-title">Összesen</span>
              <span className="order-checkout-line-amount">{totalPriceText} Ft</span>
            </div>
          </div>
          
          <div className="order-benefits">
            <div>✅ Gyors szállítás futárral</div>
            <div>✅ Biztonságos utánvétes fizetés</div>
          </div>
        </div>
      </div>
    );
  }
  
  export default SaleCrossfishNecklaceOrder;