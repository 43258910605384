import React from "react";
import "./SaleEnded.css";


export const SaleEnded = () => {
  return (
    <div className="saleEndedContainer">
         Sajnáljuk, az akció véget ért. 
    </div>
  );
};
