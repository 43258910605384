import React, { useEffect } from "react";
import "./AventurineBraceletProduct.css";
import ImageGallery from 'react-image-gallery';
import galleryImg1 from "../../../images/products/aventurine_braclet_1.jpg";
import galleryImg2 from "../../../images/products/aventurine_braclet_2.webp";
import galleryImg3 from "../../../images/products/aventurine_braclet_3.webp";
import { useNavigate } from "react-router-dom";

const images = [
  {
    original: galleryImg1,
    thumbnail: galleryImg1
  },
  {
    original: galleryImg2,
    thumbnail: galleryImg2
  },
  {
    original: galleryImg3,
    thumbnail: galleryImg3
  }
];

export const AventurineBraceletProduct = () => {
  const productName = "Zöld Aventurin karkötő";
  const navigate = useNavigate();

  const navigateToOrderDialog = () => {
    navigate('order');
  }

  useEffect(() => {
    document.title = 'ViaMedia - ' + productName;
  }, []);

  return (
    <div className="product">
      <div className="product_left">
        <div className="image-gallery-wrapper">
          <ImageGallery items={images} showFullscreenButton={false}/>      
        </div>
      </div>
      <div className="product_right">

        <h2>{productName}</h2>
        <p></p>
        <p>Az aventurin egy ásvány, amelynek neve az olasz "a ventura" szóból ered, ami <b>szerencsét</b> vagy <b>véletlent</b> jelent. Az aventurin egy változatos színű és textúrájú kvarc ásvány, amelyben apró beágyazott ásványi részecskék találhatók.</p>
        <p>Az aventurinnek a hiedelem szerint számos pozitív hatása van. Sokan úgy vélik, hogy ez az ásvány szerencsét hoz és védelmet nyújt a negatív energiákkal szemben. Emellett azt is mondják, hogy segít az érzelmi egyensúly megteremtésében, a stressz csökkentésében és a kreativitás fokozásában.</p>

        <div className="orderButtonContainer">
          <button onClick={navigateToOrderDialog} type="button" class="pulsingButton" data-toggle="modal" data-target="#myModal">Megrendelés</button>
        </div>

      </div>
  </div>

  );
};
