import React, { useEffect } from "react";
import "./BookmarkProduct.css";
import ImageGallery from 'react-image-gallery';
import galleryImg1 from "../../../images/products/bookmark_1.jpg";
import galleryImg2 from "../../../images/products/bookmark_2.jpg";
import { useNavigate } from "react-router-dom";

const images = [
  {
    original: galleryImg1,
    thumbnail: galleryImg1
  },
  {
    original: galleryImg2,
    thumbnail: galleryImg2
  }
];

export const BookmarkProduct = () => {
  const productName = "Bibliai könyvjelző";
  const navigate = useNavigate();

  const navigateToOrderDialog = () => {
    navigate('order');
  }

  useEffect(() => {
    document.title = 'ViaMedia - ' + productName;
  }, []);

  return (
    <div className="product">
      <div className="product_left">
        <div className="image-gallery-wrapper">
          <ImageGallery items={images} showFullscreenButton={false}/>      
        </div>
      </div>
      <div className="product_right">

        <h2>{productName}</h2>
        <p></p>
        <p>A könyvjelző a bibliaolvasás elengedhetetlen kiegészítője, mely lehetővé teszi az olvasó számára, hogy könnyen visszataláljon az utoljára olvasott igeszakaszkoz.</p>
        <p>Ez a fém könyvjelző tartós és elegáns megjelenésű. Vékony profilja lehetővé teszi, hogy könnyen behelyezhető legyen a Biblia vagy más könyv lapjai közé, és az olvasás közben sem zavarja az olvasót. A praktikum és az esztétikum tökéletes kombinációját képviseli.</p>
        <p></p>

        <div className="orderButtonContainer">
          <button onClick={navigateToOrderDialog} type="button" class="pulsingButton" data-toggle="modal" data-target="#myModal">Megrendelés</button>
        </div>

      </div>
  </div>

  );
};
