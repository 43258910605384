import React, { useEffect } from "react";
import "./NecklaceWithCrossProduct.css";
import ImageGallery from 'react-image-gallery';
import galleryImg1 from "../../../images/products/cross_necklace_01.webp";
import galleryImg2 from "../../../images/products/cross_necklace_02.webp";
import galleryImg3 from "../../../images/products/cross_necklace_03.webp";
import galleryImg4 from "../../../images/products/cross_necklace_04.webp";
import { useNavigate } from "react-router-dom";

const images = [
  {
    original: galleryImg1,
    thumbnail: galleryImg1
  },
  {
    original: galleryImg2,
    thumbnail: galleryImg2
  },
  {
    original: galleryImg3,
    thumbnail: galleryImg3
  },
  {
    original: galleryImg4,
    thumbnail: galleryImg4
  }
];

export const NecklaceWithCrossProduct = () => {
  const productName = "Nyaklánc kereszt medállal";
  const navigate = useNavigate();

  const navigateToOrderDialog = () => {
    navigate('order');
  }

  useEffect(() => {
    document.title = 'ViaMedia - ' + productName;
  }, []);

  return (
    <div className="product">
      <div className="product_left">
        <div className="image-gallery-wrapper">
          <ImageGallery items={images} showFullscreenButton={false}/>      
        </div>
      </div>
      <div className="product_right">


        <h2>{productName}</h2>
        <p></p>
        <p>A kereszt medál a keresztény vallás egyik legfontosabb szimbóluma, amely Jézus Krisztus keresztrefeszítését és feltámadását jelképezi. A viselése segíthet a vallási hit megerősítésében.</p>
        <p></p>
        <p>40+5cm állítható aranyozott (18 karát) lánc</p>
        <p></p>
        <p>2 cm aranyozott (18 karát) kereszt medál</p>

        <div className="orderButtonContainer">
          <button onClick={navigateToOrderDialog} type="button" class="pulsingButton" data-toggle="modal" data-target="#myModal">Megrendelés</button>
        </div>

      </div>
  </div>

  );
};
