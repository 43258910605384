import React from "react";
import "./SuccessfulOrder.css";


export const SuccessfulOrder = () => {
  return (
    <div className="successfulOrderContainer">
         A megrendelését rögzítettük! 
    </div>
  );
};
